






























































































































import {
  dispatchGetPatientCustomerServices,
  dispatchGetPatientsTimeline,
} from '@/store/crud/actions';
import {IPatientTimeline} from '@/interfaces/PatientsTimeline';
import PatientTimelineMoreInfoModal from '@/components/modals/PatientTimelineMoreInfoModal.vue';
import PatientCustomerServiceModal from '@/components/modals/PatientCustomerServiceModal.vue';
import AppComponent from '@/mixins/ComponentMixin.vue';
import Component from 'vue-class-component';
import {Watch} from 'vue-property-decorator';

@Component({components: {PatientTimelineMoreInfoModal, PatientCustomerServiceModal}})
export default class AdminPatientsTimeline extends AppComponent {
  public headers = [
    /*
    // dropping Patient customer service for performance reasons
    {
      text: 'More',
      value: 'action',
      align: 'center',
      sortable: false,
      filterable: false,
      width: 15,
    },
    */
    {
      text: 'Identifier',
      sortable: true,
      filterable: true,
      divider: true,
      value: 'patientIdentifier',
      width: 180,
      align: 'center',
    },
    {
      text: 'Email',
      sortable: true,
      filterable: true,
      divider: true,
      value: 'patientContactInfo.email',
      width: 250,
      align: 'center',
    },
    {
      text: 'First Name',
      sortable: true,
      filterable: true,
      width: 150,
      value: 'fname',
      align: 'left',
    },
    {
      text: 'Last Name',
      divider: true,
      sortable: true,
      filterable: true,
      width: 150,
      value: 'lname',
      align: 'left',
    },
    {
      text: 'Assigned to',
      sortable: true,
      filterable: true,
      width: 150,
      value: 'assignedTo',
      align: 'left',
    },
    {
      text: 'Patient joined date',
      divider: true,
      sortable: true,
      filterable: true,
      width: 100,
      value: 'createdAt',
      align: 'center',
    },
    {
      text: 'Req Approved',
      sortable: true,
      filterable: true,
      align: 'center',
      width: 100,
      value: 'latest_requisition.confirmation',
    },
    {
      text: 'Date',
      sortable: true,
      divider: true,
      filterable: true,
      width: 100,
      value: 'latest_requisition.dateTimeConfirmed',
      align: 'center',
    },
    {
      text: 'Reviewed',
      sortable: true,
      filterable: true,
      width: 100,
      value: 'latest_requisition.reviewed',
      align: 'center',
    },
    {
      text: 'Date',
      sortable: true,
      filterable: true,
      width: 100,
      divider: true,
      value: 'latest_requisition.dateTimeReviewed',
      align: 'center',
    },
    {
      text: 'All results',
      divider: true,
      sortable: true,
      filterable: true,
      width: 100,
      value: 'latest_requisition.allResultsAvailable',
      align: 'center',
    },
    {
      text: 'Visit-1 results',
      sortable: true,
      filterable: true,
      width: 100,
      value: 'latest_requisition.visits[0].resultsAvailable',
      align: 'center',
    },
    {
      text: 'Date',
      sortable: true,
      filterable: true,
      divider: true,
      width: 100,
      value: 'latest_requisition.visits[0].visitDate',
      align: 'center',
    },
    {
      text: 'Visit-2 results',
      sortable: true,
      filterable: true,
      width: 100,
      value: 'latest_requisition.visits[1].resultsAvailable',
      align: 'center',
    },
    {
      text: 'Date',
      sortable: true,
      filterable: true,
      width: 100,
      value: 'latest_requisition.visits[1].visitDate',
      align: 'center',
    },
  ];

  public search = '';
  public fetchingPatientsTimelineData = false;
  public patientsTimeline: IPatientTimeline[] = [];
  public rawPatientsTimeline: IPatientTimeline[] | null = null;
  public patientCustomerServices: any[] = [];

  /**
   * Disconnecting this logic for performance reasons
   * until new features in CRM
   */
  // @Watch('fetchingPatientsTimelineData')
  public async onFetchingPatientsTimelineDataChanged() {
    // FIXME - temp change, this should be done in the CRM service
    if (!this.fetchingPatientsTimelineData) {
      // when fetching is done, data should be digested
      // assign each patient to a customer service
      if (!this.patientCustomerServices || this.patientCustomerServices.length === 0) {
        this.patientsTimeline = !!this.rawPatientsTimeline ? this.rawPatientsTimeline : [];
      }
      this.patientsTimeline = this.rawPatientsTimeline!.map((patient) => {
        const patientCustomerService = this.patientCustomerServices.find(
          (pcs) => pcs.patientId === patient.patientId,
        );
        return {
          ...patient,
          assignedTo: patientCustomerService
            ? `${patientCustomerService.assignedUser?.fname ?? ''} ${patientCustomerService
                .assignedUser?.lname ?? ''}`.trim()
            : 'N/A',
        };
      });
    }
  }

  public async mounted() {
    this.fetchData();
  }

  public openNewWindow(patientId: string) {
    const routeUrl = this.$router.resolve({
      name: 'main-admin-patients-customer-service',
      params: {patientId},
    });
    window.open(
      routeUrl.route.fullPath,
      '_blank',
      'location=yes,height=570,width=900,scrollbars=yes,status=yes,menubar=no',
    );
  }

  public async fetchData() {
    this.fetchingPatientsTimelineData = true;
    await this.$nextTick();
    this.rawPatientsTimeline = (await dispatchGetPatientsTimeline(
      this.$store,
    )) as IPatientTimeline[];
    // fetch patientCustomerService data
    // this.patientCustomerServices = await dispatchGetPatientCustomerServices(this.$store);
    this.fetchingPatientsTimelineData = false;
  }
}
