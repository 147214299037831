


















/**
 * @Author mcastrucci
 *
 */
import {Component, Prop} from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';
import PatientCustomerService from '@/components/PatientCustomerService.vue';

@Component({components: {PatientCustomerService}})
export default class PatientCustomerServiceModal extends AppComponent {
  @Prop({type: String, default: ''}) public id: string;

  public dialog = false;
}
