var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"light":""}},[_c('v-toolbar-title',{staticClass:"display-1"},[_vm._v("Patients Timeline ")])],1),(_vm.fetchingPatientsTimelineData)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"items-per-page":10,"dense":"","headers":_vm.headers,"search":_vm.search,"item-key":"patientId","items":_vm.rawPatientsTimeline,"footer-props":{
        'items-per-page-options': [10, 20, 50, 100, 200, 1000],
      }},scopedSlots:_vm._u([{key:"item.patientIdentifier",fn:function(props){return [_c('router-link',{attrs:{"target":"_blank","to":{name: 'main-admin-patients-edit', params: {id: props.item.patientId}}}},[_vm._v(" "+_vm._s(props.item.patientIdentifier)+" ")])]}},{key:"item.patientContactInfo.email",fn:function(props){return [_c('router-link',{attrs:{"target":"_blank","to":{name: 'main-admin-patients-edit', params: {id: props.item.patientId}}}},[_vm._v(" "+_vm._s(props.item.patientContactInfo ? props.item.patientContactInfo.email || '' : '')+" ")])]}},{key:"item.createdAt",fn:function(props){return [_vm._v(" "+_vm._s(_vm.convertUtcStringToLocalDate(props.item.createdAt))+" ")]}},{key:"item.latest_requisition.dateTimeConfirmed",fn:function(props){return [_vm._v(" "+_vm._s(_vm.convertUtcStringToLocalDate(props.item.latest_requisition.dateTimeConfirmed))+" ")]}},{key:"item.latest_requisition.dateTimeReviewed",fn:function(props){return [_vm._v(" "+_vm._s(_vm.convertUtcStringToLocalDate(props.item.latest_requisition.dateTimeReviewed))+" ")]}},{key:"item.latest_requisition.visits[0].visitDate",fn:function(props){return [(
            props.item.latest_requisition.visits &&
              props.item.latest_requisition.visits.length > 0 &&
              props.item.latest_requisition.visits[0].visitDate
          )?_c('div',[_vm._v(" "+_vm._s(_vm.convertUtcStringToLocalDate(props.item.latest_requisition.visits[0].visitDate))+" ")]):_vm._e()]}},{key:"item.latest_requisition.visits[1].visitDate",fn:function(props){return [(
            props.item.latest_requisition.visits &&
              props.item.latest_requisition.visits.length > 1 &&
              props.item.latest_requisition.visits[1].visitDate
          )?_c('div',[_vm._v(" "+_vm._s(_vm.convertUtcStringToLocalDate(props.item.latest_requisition.visits[1].visitDate))+" ")]):_vm._e()]}},{key:"item.latest_requisition.confirmation",fn:function(props){return [_vm._v(" "+_vm._s(props.item.latest_requisition.confirmation ? 'Yes' : 'No')+" ")]}},{key:"item.latest_requisition.reviewed",fn:function(props){return [_vm._v(" "+_vm._s(props.item.latest_requisition.reviewed ? 'Yes' : 'No')+" ")]}},{key:"item.latest_requisition.allResultsAvailable",fn:function(props){return [_vm._v(" "+_vm._s(props.item.latest_requisition.allResultsAvailable ? 'Yes' : 'No')+" ")]}},{key:"item.latest_requisition.visits[0].resultsAvailable",fn:function(props){return [_vm._v(" "+_vm._s(props.item.latest_requisition.visits && props.item.latest_requisition.visits.length > 0 && props.item.latest_requisition.visits[0].resultsAvailable ? 'Yes' : 'No')+" ")]}},{key:"item.latest_requisition.visits[1].resultsAvailable",fn:function(props){return [_vm._v(" "+_vm._s(props.item.latest_requisition.visits && props.item.latest_requisition.visits.length > 1 && props.item.latest_requisition.visits[1].resultsAvailable ? 'Yes' : 'No')+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }