











































































































































































import AppComponent from '@/mixins/ComponentMixin.vue';
import PatientCustomerService from '@/components/PatientCustomerService.vue';
import {Component, Prop, Watch} from 'vue-property-decorator';

@Component({
  name: 'PatientTimelineMoreInfoModal',
  components: {
    PatientCustomerService,
  },
})
export default class PatientTimelineMoreInfoModal extends AppComponent {
  @Prop({type: Object, default: {}}) public item: any;
  public customerServiceVisible = false;
  public showCustomerServiceButton = true;

  public dialog = false;

  // watch for vuetify breakpoint changes
  @Watch('$vuetify.breakpoint.smAndDown', {immediate: true, deep: true})
  public onBreakpointChanged() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.showCustomerServiceButton = false;
      this.customerServiceVisible = true;
    } else {
      this.showCustomerServiceButton = true;
      this.customerServiceVisible = false;
    }
  }
}
